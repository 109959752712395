@font-face {
  font-family: "e-Ukraine";
  font-weight: normal;
  src: local("e-Ukraine"),
    url("/src/assets/fonts/e-Ukraine-Regular.otf") format("truetype");
}

/* @font-face {
  font-family: 'e-Ukraine';
  font-weight: 600;
  src: local('e-Ukraine'), url("/src/assets/fonts/e-Ukraine.ttf") format('opentype');
} */

@font-face {
  font-family: "HelveticaNeueCyr";
  font-weight: 400;
  src: local("HelveticaNeueCyr"),
    url("/src/assets/fonts/HelveticaNeueCyr-Roman.otf") format("opentype");
}

@font-face {
  font-family: "HelveticaNeueCyr";
  font-weight: 550;
  src: local("HelveticaNeueCyr"),
    url("/src/assets/fonts/HelveticaNeueCyr-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "HelveticaNeueCyr";
  font-weight: 700;
  src: local("HelveticaNeueCyr"),
    url("/src/assets/fonts/HelveticaNeueCyr-Black.otf") format("opentype");
}

/* Dia fonts */
@font-face {
  font-family: "e-Ukraine";
  font-weight: 400;
  src: local("e-Ukraine"),
    url("/src/assets/fonts/e-Ukraine-Regular.otf") format("opentype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
body div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;

  -webkit-filter: invert(0%);
  filter: invert(0%);
}

img {
  -webkit-filter: invert(0%) !important;
  filter: invert(0%) !important;
}

:root {
  --root-white: #ffffff;
  --root-black: #000000;
}

@media (prefers-color-scheme: dark) {
  :root {
    --root-white: #ffffff;
    --root-black: #000000;
  }
}

button {
  cursor: pointer;
  outline: none;
  background-color: transparent;
  border: none;
  padding: 0;
}

.required {
  color: #ff0101;
}

.formErrorContainer {
  height: 24px;
  font-size: 11px;
  line-height: 16px;
  color: #d92a2a;
}

*:focus {
  outline: 2px solid #0a8ff5;
  outline-offset: 4px;
  transition: outline 0.3s ease, outline-offset 0.3s ease;
}
